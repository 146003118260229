<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden bg-porcelain-200/50 flex flex-wrap items-center justify-between relative md:w-64 z-20 py-4 px-0"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        @click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <i class="fas fa-bars"></i>
      </button>
      <!-- Brand -->
      <router-link
        class="md:block text-left text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold px-0"
        to="/"
      >
        <img src="/img/notedsource-logo.svg" class="w-[197px] h-[35px] ml-3" />
      </router-link>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative mr-3">
          <user-dropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        :class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-white-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                to="/"
              >
                <img src="/img/notedsource-logo.svg" class="mx-auto" />
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                @click="toggleCollapseShow('hidden')"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="px-1 pb-3 mt-10">
          <!-- Divider -->

          <ul
            class="md:flex-col md:min-w-full flex flex-col list-none md:ml-3 md:pl-3"
          >
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/profiles`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  People & Profiles
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/organizations`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Organizations
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/collaboration-spaces`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Collaboration Spaces
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/firms`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Firms
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/collaboration-requests`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Collaboration Requests
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/collaboration-inquiries`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Collaboration Inquiries
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/work`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Work
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/meetings`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Meetings
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/contract-templates`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Contract Templates
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/contracts`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Contracts
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/contract-packages`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Contract Packages
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/invoices`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Invoices
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/finance`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Finance
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/research-communities`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Research Communities
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/profile-search-2`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Expert Search
                </a>
              </router-link>
            </li>
          </ul>
          <h5
            class="uppercase ml-3 text-black/80 text-sm pl-3 py-3 cursor-pointer"
            @click="toggleShowMore()"
          >
            <i
              class="fa-solid fa-caret-right transition-all duration-150 mr-1"
              :class="{ 'rotate-90': showMore }"
            ></i>
            More...
          </h5>
          <ul
            v-if="showMore"
            class="md:flex-col md:min-w-full flex flex-col list-none ml-3 pl-3"
          >
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/roles`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Roles
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/role-permissions`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Role Permissions
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/rss-feed-publishers`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  RSS Feeds
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/expert-dashboard`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Expert Dashboard Content
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/contract-types`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Contract Types
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/data-integrity`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Data Integrity
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/feature-pops`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Feature Pops
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/problems`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Reported Problems
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/sites`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Sites
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/site-themes`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Site Themes
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/email-templates`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Email Templates
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/onespan-contracts`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-bianca' : '']"
                  @click="navigate"
                >
                  OneSpan Contracts
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/crawler`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Crawler
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/scraper`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-bianca' : '']"
                  @click="navigate"
                >
                  Scraper
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/expert-newsletter`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-bianca' : '']"
                  @click="navigate"
                >
                  Expert Newsletter
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/collaboragent`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Collaboragent
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/sources-of-note`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Sources of Note
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/meeting-experiment`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Meeting Experiment
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/perplexica-experiment`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Perplexica Experiment
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/research-compass-pipeline`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  Research Compass Pipeline
                </a>
              </router-link>
            </li>
            <li v-if="isDevMode" class="items-center">
              <router-link
                v-slot="{ href, navigate, isActive }"
                :to="`/admin/playground`"
              >
                <a
                  :href="href"
                  class="pl-5 py-2 block rounded-l-lg"
                  :class="[isActive ? 'bg-porcelain' : '']"
                  @click="navigate"
                >
                  UI Playground
                </a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <id-decoder class="hidden md:block" />
      <div class="flex justify-between px-5 pb-3 hidden md:block">
        <router-link
          to="/auth/logout"
          class="rounded-lg bg-porcelain-200/80 text-porcelain-900/75 hover:text-porcelain-900 px-3 py-1 border border-porcelain-300"
          type="button"
        >
          Logout
        </router-link>
        <router-link
          to="/admin/settings"
          class="rounded-lg bg-porcelain-200/80 text-porcelain-900/75 hover:text-porcelain-900 px-3 py-1 border border-porcelain-300 ml-3"
          type="button"
        >
          Settings
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import UserDropdown from "../Dropdowns/UserDropdown.vue";
import IdDecoder from "../IdDecoder.vue";

export default {
  components: {
    UserDropdown,
    IdDecoder,
  },
  data() {
    return {
      appMode: `${import.meta.env.MODE}`,
      collapseShow: "hidden",
      showMore: false,
    };
  },
  computed: {
    isDevMode() {
      return this.appMode === "development";
    },
    profiles() {
      return this.$store.getters.person?.profiles;
    },
  },
  methods: {
    toggleCollapseShow(classes) {
      this.collapseShow = classes;
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
  },
};
</script>
