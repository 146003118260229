<template>
  <div class="bg-white rounded-lg shadow-lg mx-5 my-5 px-5 py-3">
    <div class="flex justify-between">
      <div class="w-3/4 pl-5">
        <div class="absolute -ml-5">
          <button @click="toggleExpanded()">
            <i
              :class="{ 'rotate-90': expanded }"
              class="text-lg fa-solid fa-caret-right"
            ></i>
          </button>
        </div>
        <button @click="toggleExpanded()">
          <h1 class="text-2xl">{{ collaborationSpace.name }}</h1>
        </button>
        <h4 v-if="collaborationSpace.organizationName" class="text">
          {{ collaborationSpace.organizationName
          }}<span v-if="collaborationSpace.organizationStatus">
            • {{ collaborationSpace.organizationStatus[0] }}</span
          >
        </h4>
        <div
          v-if="!expanded && (hasUnmappedDebits || hasOrphanInvoiceLineItems)"
          class="inline-block px-2 pb-1 pt-[5px] bg-orange-200 border border-orange-400 text-black/50 text-sm rounded"
        >
          <p v-if="hasUnmappedDebits">
            <i class="fa-solid fa-triangle-exclamation"></i> Unmapped debits.
          </p>
          <p v-if="hasOrphanInvoiceLineItems">
            <i class="fa-solid fa-triangle-exclamation"></i> Unconnected invoice
            line items.
          </p>
        </div>
        <div
          v-else-if="!expanded"
          class="inline-block px-2 pb-1 pt-[5px] bg-green-200 border border-green-400 text-black/50 text-sm rounded"
        >
          <p><i class="fa-solid fa-check"></i> Looks good.</p>
        </div>
      </div>
      <div class="w-1/4">
        <div class="flex w-full justify-between">
          <div>
            <span class="text-sm uppercase font-bold">Credit Balance:</span>
          </div>
          <div>{{ collaborationSpace.remainingCreditBalance }}</div>
        </div>
        <div class="flex w-full justify-between">
          <div>
            <span class="text-sm uppercase font-bold"
              >Total Amount Collected:</span
            >
          </div>
          <div>
            {{ USDollar.format(collaborationSpace.totalAmountCollected) }}
          </div>
        </div>
        <div class="flex w-full justify-between">
          <div>
            <span class="text-sm uppercase font-bold"
              >Total Amount Paid Out:</span
            >
          </div>
          <div>
            {{ USDollar.format(collaborationSpace.totalAmountPaidOut) }}
          </div>
        </div>
      </div>
    </div>
    <template v-if="expanded">
      <div v-if="collaborationSpace.creditTransactions.length > 0" class="mt-3">
        <table class="border border-2 w-full">
          <thead class="bg-porcelain">
            <tr>
              <th class="uppercase text-xs text-left border px-2 py-1">Date</th>
              <th class="uppercase text-xs text-left border px-2 py-1">Type</th>
              <th class="uppercase text-xs text-left border px-2 py-1">
                $ / Credit
              </th>
              <th class="uppercase text-xs text-left border px-2 py-1">Qty</th>
              <th class="uppercase text-xs text-left border px-2 py-1">
                Detail
              </th>
              <th class="uppercase text-xs text-left border px-2 py-1">
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="transaction in collaborationSpace.creditTransactions"
              :key="transaction.hashedId"
            >
              <td class="border px-2 py-1 align-top">
                {{ $dayjs(transaction.clientReflectedAt).format("MM/DD/YYYY") }}
              </td>
              <td class="border px-2 py-1 align-top">
                <span
                  class="text-sm rounded px-1 uppercase font-bold bg-porcelain-400"
                  :class="{
                    'bg-red-500 text-white':
                      transaction.transactionType[0] == 'Debit',
                    'bg-green-500 text-white':
                      transaction.transactionType[0] == 'Credit',
                  }"
                  >{{ transaction.transactionType[0] }}</span
                >
              </td>
              <td class="border align-top">
                <template
                  v-if="
                    transaction.transactionType[0] == 'Debit' ||
                    transaction.transactionType[0] == 'Transfer (out)'
                  "
                >
                  <div class="px-2 py-1">
                    ({{ USDollar.format(transaction.amountPaidPerCredit) }})
                  </div>
                  <div class="bg-blue-500/30 px-3 pt-2">
                    <p class="text-xs uppercase font-bold">Margin / Credit</p>
                    {{
                      USDollar.format(
                        transaction.averageCostPerCredit -
                          transaction.amountPaidPerCredit,
                      )
                    }}
                  </div>
                </template>
                <template v-else>
                  <div class="px-2 py-1">
                    {{ USDollar.format(transaction.costPerCredit) }}
                  </div>
                </template>
              </td>
              <td class="border px-2 py-1 align-top">
                <span v-if="transaction.transactionType[0] == 'Debit'"
                  >({{ transaction.quantity }})</span
                >
                <span v-else>{{ transaction.quantity }}</span>
              </td>
              <td class="border p-0 align-top">
                <div v-if="transaction.clientDescription" class="px-2 py-1">
                  <p class="uppercase font-bold text-xs text-porcelain-400">
                    Client Description
                  </p>
                  {{ transaction.clientDescription }}
                </div>
                <div v-if="transaction.internalNotes" class="px-2 py-1">
                  <p class="uppercase font-bold text-xs text-porcelain-400">
                    Internal Notes
                  </p>
                  {{ transaction.internalNotes }}
                </div>
                <table
                  v-if="transaction.invoiceLineItems?.length > 0"
                  class="w-full m-0"
                >
                  <thead class="bg-porcelain">
                    <tr>
                      <th
                        class="uppercase text-xs text-left border px-2 py-1 text-porcelain-400"
                      >
                        Date
                      </th>
                      <th
                        class="uppercase text-xs text-left border px-2 py-1 text-porcelain-400"
                      >
                        Invoice Status
                      </th>
                      <th
                        class="uppercase text-xs text-left border px-2 py-1 text-porcelain-400"
                      >
                        Amount
                      </th>
                      <th
                        class="uppercase text-xs text-left border px-2 py-1 text-porcelain-400"
                      >
                        Person
                      </th>
                      <th
                        class="uppercase text-xs text-left border px-2 py-1 text-porcelain-400"
                      >
                        Detail
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="lineItem in transaction.invoiceLineItems"
                      :key="lineItem.hashedId"
                    >
                      <td class="border px-2 py-1 align-top">
                        {{ $dayjs(lineItem.date).format("MM/DD/YYYY") }}
                      </td>
                      <td class="border px-2 py-1 align-top">
                        {{ lineItem.invoiceStatus[0] }}
                      </td>
                      <td class="border px-2 py-1 align-top">
                        {{ USDollar.format(lineItem.amount) }}
                      </td>
                      <td class="border px-2 py-1 align-top">
                        {{ lineItem.personFullName }}
                      </td>
                      <td class="border px-2 py-1 align-top">
                        <p
                          class="uppercase font-bold text-xs text-porcelain-400"
                        >
                          Project
                        </p>
                        {{ lineItem.workTitle }}
                        <p
                          v-if="lineItem.notes"
                          class="uppercase font-bold text-xs text-porcelain-400"
                        >
                          Client Notes
                        </p>
                        {{ lineItem.notes }}
                        <p
                          v-if="lineItem.clientNotes"
                          class="uppercase font-bold text-xs text-porcelain-400"
                        >
                          Admin Notes
                        </p>
                        {{ lineItem.adminNotes }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else-if="transaction.transactionType[0] == 'Debit'"
                  class="bg-orange-100 px-2 py-1"
                >
                  <em>There are no line items associated with this debit.</em>
                </div>
              </td>
              <td class="border px-2 py-1 align-top">
                {{ transaction.runningBalance }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="collaborationSpace.orphanInvoiceLineItems.length > 0"
        class="bg-orange-100 px-3 py-2 mt-3"
      >
        The following invoice line items are connected to work in this
        Collaboration Space but are
        <strong>not tied to a credit transaction</strong> and could not be
        included in the credit consumption calculations. To connect them, go to
        the invoice in Admin, select a line item, and choose the correct credit
        transaction.
        <table
          class="border border-2 border-orange-400 bg-orange-200 w-full mt-2"
        >
          <thead>
            <tr>
              <th
                class="uppercase text-xs text-left border border-orange-400 px-2 py-1"
              >
                Date
              </th>
              <th
                class="uppercase text-xs text-left border border-orange-400 px-2 py-1"
              >
                Invoice Status
              </th>
              <th
                class="uppercase text-xs text-left border border-orange-400 px-2 py-1"
              >
                Amount
              </th>
              <th
                class="uppercase text-xs text-left border border-orange-400 px-2 py-1"
              >
                Person
              </th>
              <th
                class="uppercase text-xs text-left border border-orange-400 px-2 py-1"
              >
                Detail
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="lineItem in collaborationSpace.orphanInvoiceLineItems"
              :key="lineItem.hashedId"
            >
              <td class="border border-orange-400 px-2 py-1">
                {{ $dayjs(lineItem.date).format("MM/DD/YYYY") }}
              </td>
              <td class="border border-orange-400 px-2 py-1">
                {{ lineItem.invoiceStatus[0] }}
              </td>
              <td class="border border-orange-400 px-2 py-1">
                {{ USDollar.format(lineItem.amount) }}
              </td>
              <td class="border border-orange-400 px-2 py-1">
                {{ lineItem.personFullName }}
              </td>
              <td class="border border-orange-400 px-2 py-1">
                <p class="uppercase font-bold text-xs text-black/30">Project</p>
                {{ lineItem.workTitle }}
                <p
                  v-if="lineItem.notes"
                  class="uppercase font-bold text-xs text-black/30"
                >
                  Client Notes
                </p>
                {{ lineItem.notes }}
                <p
                  v-if="lineItem.clientNotes"
                  class="uppercase font-bold text-xs text-black/30"
                >
                  Admin Notes
                </p>
                {{ lineItem.adminNotes }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "AdminFinanceCollaborationSpace",
  components: {},
  props: {
    collaborationSpace: Object,
  },
  data() {
    return {
      expanded: false,
      USDollar: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }),
    };
  },
  computed: {
    hasUnmappedDebits() {
      let result = false;
      this.collaborationSpace.creditTransactions?.forEach((txn) => {
        if (
          txn.transactionType[0] === "Debit" &&
          !txn.invoiceLineItems.length
        ) {
          result = true;
        }
      });
      return result;
    },
    hasOrphanInvoiceLineItems() {
      return this.collaborationSpace.orphanInvoiceLineItems.length > 0;
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
