<template>
  <div class="flex flex-wrap h-screen">
    <div class="w-full h-full">
      <div
        v-for="collaborationSpace in collaborationSpaces"
        :key="collaborationSpace.hashedId"
      >
        <finance-collaboration-space
          :collaboration-space="collaborationSpace"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import FinanceCollaborationSpace from "../../components/FinanceCollaborationSpace.vue";

const { keysToCamel } = utils;

export default {
  name: "AdminFinance",
  components: {
    FinanceCollaborationSpace,
  },
  data() {
    return {
      collaborationSpaces: {},
    };
  },
  mounted() {
    this.getSpacesInformation();
  },
  methods: {
    getSpacesInformation() {
      this.$api.axios.get("/finance/").then((resp) => {
        const data = keysToCamel(resp.data);
        this.collaborationSpaces = data.collaborationSpaces;
      });
    },
  },
};
</script>
