<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Research Compass Pipeline
          </h3>
        </div>
      </div>
    </div>
    <div class="flex px-8">
      <div class="ml-10">
        <table>
          <thead>
            <tr>
              <th class="border px-5 py-2">Release</th>
              <th
                v-for="release in recentReleases"
                :key="release"
                class="border px-5 py-2"
              >
                {{ release }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border px-5 py-2">
                Manifests
                <div>
                  <button @click="getManifestBlobs()">
                    <i class="fa-solid fa-rotate-right"></i>
                  </button>
                </div>
              </td>
              <th
                v-for="release in recentReleases"
                :key="release"
                class="border px-5 py-2"
              >
                <template v-if="loadingManifestBlobs"> Loading... </template>
                <template v-else>
                  <div class="flex">
                    <div>
                      <div v-for="dataset in relevantDatasets" :key="dataset">
                        <button @click="generateManifest(release, dataset)">
                          {{ dataset }}</button
                        >:
                        {{ manifestBlobsForRelease(release, dataset) }}
                      </div>
                    </div>
                  </div>
                </template>
              </th>
            </tr>
            <tr>
              <td class="border px-5 py-2">
                Compressed Files
                <div>
                  <button @click="getSemanticScholarStagingBlobs()">
                    <i class="fa-solid fa-rotate-right"></i>
                  </button>
                </div>
              </td>
              <th
                v-for="release in recentReleases"
                :key="release"
                class="border px-5 py-2"
              >
                <template v-if="loadingSemanticScholarStagingBlobs">
                  Loading...
                </template>
                <template v-else>
                  <div class="flex">
                    <div>
                      <div v-for="dataset in relevantDatasets" :key="dataset">
                        <button
                          @click="
                            createSemanticScholarTransferJob(release, dataset)
                          "
                        >
                          {{ dataset }}</button
                        >:
                        {{
                          semanticScholarStagingBlobsForRelease(
                            release,
                            dataset,
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </template>
              </th>
            </tr>
            <tr>
              <td class="border px-5 py-2">
                Decompressed Files
                <div>
                  <button @click="getSemanticScholarDecompressedBlobs()">
                    <i class="fa-solid fa-rotate-right"></i>
                  </button>
                </div>
              </td>
              <th
                v-for="release in recentReleases"
                :key="release"
                class="border px-5 py-2"
              >
                <template v-if="loadingSemanticScholarDecompressedBlobs">
                  Loading...
                </template>
                <template v-else>
                  <div class="flex">
                    <div>
                      <div v-for="dataset in relevantDatasets" :key="dataset">
                        <button
                          @click="decompressSemanticScholar(release, dataset)"
                        >
                          {{ dataset }}</button
                        >:
                        {{
                          semanticScholarDecompressedBlobsForRelease(
                            release,
                            dataset,
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </template>
              </th>
            </tr>
            <tr>
              <td class="border px-5 py-2">
                BigQuery Datasets
                <div>
                  <button @click="getBigqueryDatasets()">
                    <i class="fa-solid fa-rotate-right"></i>
                  </button>
                </div>
              </td>
              <th
                v-for="release in recentReleases"
                :key="release"
                class="border px-5 py-2"
              >
                <template v-if="loadingBigqueryDatasets"> Loading... </template>
                <template v-else>
                  <div class="flex">
                    <div>
                      <button @click="createBigqueryDataset(release)">
                        dataset</button
                      >:
                      {{ bigqueryDatasetsForRelease(release) }}
                    </div>
                  </div>
                </template>
              </th>
            </tr>
            <tr>
              <td class="border px-5 py-2">
                BigQuery Tables
                <div>
                  <button @click="getBigqueryTables()">
                    <i class="fa-solid fa-rotate-right"></i>
                  </button>
                </div>
              </td>
              <th
                v-for="release in recentReleases"
                :key="release"
                class="border px-5 py-2"
              >
                <template v-if="loadingBigqueryTables"> Loading... </template>
                <template v-else>
                  <div class="flex">
                    <div>
                      <div v-for="dataset in relevantDatasets" :key="dataset">
                        <button @click="importJsonToBigquery(release, dataset)">
                          {{ dataset }}</button
                        >:
                        {{ bigqueryTablesForRelease(release, dataset) }}
                      </div>
                    </div>
                  </div>
                </template>
              </th>
            </tr>
            <tr>
              <td class="border px-5 py-2">
                BigQuery Schema Changes
                <div>
                  <button @click="getBigquerySchemaChanges()">
                    <i class="fa-solid fa-rotate-right"></i>
                  </button>
                </div>
              </td>
              <th
                v-for="release in recentReleases"
                :key="release"
                class="border px-5 py-2"
              >
                <template v-if="loadingBigquerySchemaChanges">
                  Loading...
                </template>
                <template v-else>
                  <div class="flex">
                    <div>
                      <div v-for="dataset in relevantDatasets" :key="dataset">
                        <button @click="changeBigquerySchema(release, dataset)">
                          {{ dataset }}</button
                        >:
                        {{ bigquerySchemaChangesForRelease(release, dataset) }}
                      </div>
                    </div>
                  </div>
                </template>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";

const { keysToCamel, keysToSnake } = utils;

export default {
  name: "ResearchCompassPipeline",
  components: {},
  data() {
    return {
      relevantDatasets: [
        "papers",
        "authors",
        "abstracts",
        "tldrs",
        "publication-venues",
      ],
      releases: [],
      manifestBlobs: [],
      loadingManifestBlobs: false,
      loadingSemanticScholarStagingBlobs: false,
      semanticScholarStagingBlobs: [],
      loadingSemanticScholarDecompressedBlobs: false,
      semanticScholarDecompressedBlobs: [],
      bigqueryDatasets: [],
      loadingBigqueryDatasets: false,
      bigqueryTables: {},
      loadingBigqueryTables: false,
      loadingBigquerySchemaChanges: false,
      bigQuerySchemaChanges: [],
    };
  },
  computed: {
    recentReleases() {
      return this.releases.slice(-5);
    },
  },
  mounted() {
    this.getReleases();
    this.getManifestBlobs();
    this.getSemanticScholarStagingBlobs();
    this.getSemanticScholarDecompressedBlobs();
    this.getBigqueryDatasets();
    this.getBigqueryTables();
    this.getBigquerySchemaChanges();
  },
  methods: {
    getReleases() {
      this.$api.axios
        .get(`/research-compass-pipeline/semantic-scholar/releases/`)
        .then((res) => {
          this.releases = keysToCamel(res.data.releases);
        });
    },
    getManifestBlobs() {
      this.loadingManifestBlobs = true;
      this.$api.axios
        .get(`/research-compass-pipeline/cloud-storage/files/`, {
          params: keysToSnake({ prefix: "manifests" }),
        })
        .then((res) => {
          this.manifestBlobs = keysToCamel(res.data.blobs);
          this.loadingManifestBlobs = false;
        });
    },
    manifestBlobsForRelease(release, dataset) {
      let count = 0;
      this.manifestBlobs.forEach((blob) => {
        const blobName = blob.replace("manifests/", "");
        if (blobName.startsWith(release)) {
          const blobNameParts = blobName.split("/");
          if (blobNameParts[1].startsWith(dataset)) {
            count += 1;
          }
        }
      });
      return count;
    },
    generateManifest(release, dataset) {
      this.loadingManifestBlobs = true;
      this.$api.axios
        .get(`/research-compass-pipeline/generate-semantic-scholar-manifest/`, {
          params: keysToSnake({ release, dataset }),
        })
        .then(() => {
          this.getManifestBlobs();
        });
    },
    getSemanticScholarStagingBlobs() {
      this.loadingSemanticScholarStagingBlobs = true;
      this.$api.axios
        .get(`/research-compass-pipeline/cloud-storage/files/`, {
          params: keysToSnake({ prefix: "ai2-s2ag.s3.amazonaws.com/staging" }),
        })
        .then((res) => {
          this.semanticScholarStagingBlobs = keysToCamel(res.data.blobs);
          this.loadingSemanticScholarStagingBlobs = false;
        });
    },
    semanticScholarStagingBlobsForRelease(release, dataset) {
      let count = 0;
      this.semanticScholarStagingBlobs.forEach((blob) => {
        const blobName = blob.replace("ai2-s2ag.s3.amazonaws.com/staging/", "");
        if (blobName.startsWith(release)) {
          const blobNameParts = blobName.split("/");
          if (blobNameParts[1] === dataset) {
            count += 1;
          }
        }
      });
      return count;
    },
    createSemanticScholarTransferJob(release, dataset) {
      this.loadingSemanticScholarStagingBlobs = true;
      this.$api.axios
        .get(
          `/research-compass-pipeline/create-semantic-scholar-transfer-job/`,
          {
            params: keysToSnake({ release, dataset }),
          },
        )
        .then(() => {
          this.getSemanticScholarStagingBlobs();
        });
    },
    getSemanticScholarDecompressedBlobs() {
      this.loadingSemanticScholarDecompressedBlobs = true;
      this.$api.axios
        .get(`/research-compass-pipeline/cloud-storage/files/`, {
          params: keysToSnake({ prefix: "decompressed" }),
        })
        .then((res) => {
          this.semanticScholarDecompressedBlobs = keysToCamel(res.data.blobs);
          this.loadingSemanticScholarDecompressedBlobs = false;
        });
    },
    semanticScholarDecompressedBlobsForRelease(release, dataset) {
      let count = 0;
      this.semanticScholarDecompressedBlobs.forEach((blob) => {
        const blobName = blob.replace("decompressed/", "");
        if (blobName.startsWith(release)) {
          const blobNameParts = blobName.split("/");
          if (blobNameParts[1] === dataset) {
            count += 1;
          }
        }
      });
      return count;
    },
    decompressSemanticScholar(release, dataset) {
      this.loadingSemanticScholarDecompressedBlobs = true;
      this.$api.axios
        .get(`/research-compass-pipeline/decompress-semantic-scholar/`, {
          params: keysToSnake({ release, dataset }),
        })
        .then(() => {
          this.getSemanticScholarDecompressedBlobs();
        });
    },
    createBigqueryDataset(release) {
      this.loadingBigqueryDatasets = true;
      this.$api.axios
        .get(`/research-compass-pipeline/create-bigquery-dataset/`, {
          params: keysToSnake({ release }),
        })
        .then(() => {
          this.getBigqueryDatasets();
        });
    },
    getBigqueryDatasets() {
      this.loadingBigqueryDatasets = true;
      this.$api.axios
        .get(`/research-compass-pipeline/bigquery-datasets/`)
        .then((res) => {
          this.bigqueryDatasets = keysToCamel(res.data.datasets);
          this.loadingBigqueryDatasets = false;
        });
    },
    bigqueryDatasetsForRelease(release) {
      let count = 0;
      this.bigqueryDatasets.forEach((dataset) => {
        if (dataset === `research_compass_${release.replaceAll("-", "_")}`) {
          count += 1;
        }
      });
      return count;
    },
    getBigqueryTables() {
      this.loadingBigqueryTables = true;
      this.$api.axios
        .get(`/research-compass-pipeline/bigquery-tables/`, {
          params: keysToSnake({ releases: this.recentReleases }),
        })
        .then((res) => {
          this.bigqueryTables = keysToCamel(res.data.tables);
          this.loadingBigqueryTables = false;
        });
    },
    bigqueryTablesForRelease(release, dataset) {
      return this.bigqueryTables[release]?.indexOf(dataset) > -1 ? 1 : 0;
    },
    importJsonToBigquery(release, dataset) {
      this.loadingBigqueryTables = true;
      this.$api.axios
        .get(`/research-compass-pipeline/import-bigquery-data/`, {
          params: keysToSnake({ release, dataset }),
        })
        .then(() => {
          this.getBigqueryTables();
        });
    },
    getBigquerySchemaChanges() {
      this.loadingBigquerySchemaChanges = true;
      this.loadingBigquerySchemaChanges = false;
    },
    bigquerySchemaChangesForRelease(release, dataset) {
      return this.bigqueryTables[release]?.indexOf(dataset) > -1 ? 1 : 0;
    },
    changeBigquerySchema(release, dataset) {
      this.loadingBigquerySchemaChanges = true;
      this.$api.axios
        .get(`/research-compass-pipeline/manipulate-bigquery-tables/`, {
          params: keysToSnake({ release, dataset }),
        })
        .then(() => {
          this.getBigqueryDatasets();
        });
    },
  },
};
</script>
